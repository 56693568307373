<template>
  <PageTextPage
    :page="page"/>
</template>

<script>
import { get } from 'lodash'
import AppRouteMixin from '~/mixins/AppRouteMixin'
import PageTextPage from '@theme/components/content/PageTextPage'

const PAGE = {
  name: 'RouteTextPage',
  nuxtI18n: {
    paths: {
      cs: '/stranka/:slug',
      de: '/seite/:slug',
      en: '/page/:slug',
      sk: '/stranka/:slug',
    },
  },
  middleware({ store }) {
    store.commit('locale/SET_ALTERNATIVE_PATHS', PAGE.nuxtI18n.paths)
  },
  components: {
    PageTextPage,
  },
  mixins: [AppRouteMixin],
  async asyncData({ app, store, params, redirect, route, error }) {
    const page = store.dispatch('page/LOAD_PAGE', {
      slug: store.state.globals.urlRewriteSlug ? store.state.globals.urlRewriteSlug : params.slug,
      checkPermissions: true,
    })
    const data = {
      page,
    }
    const response = await Promise.allValues(data, [store.dispatch('menu/FETCH_MENU', 'about_purchase')])
    if (!response.page) {
      return app.$pageNotFound({ path: route.path })
    }
    if (response.page.error) {
      return error({ statusCode: response.page.error.statusCode })
    }
    if (route.path !== response.page.url_rewrite && response.page.url_rewrite) {
      return redirect(301, response.page.url_rewrite)
    }
    store.commit('locale/SET_CANONICALS', {
      canonicalSlugs: get(response, 'page.page_meta.canonical_slugs', []),
    })
    return response
  },
}
export default PAGE
</script>
